import React from "react"

import SourceBackground from "./sourceBackground"
import Loader from "./loader"
import styled from "styled-components"

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`

const LazyBackground = ({ className, source, items }) => {
  const loaded = SourceBackground(source)

  return loaded === null ? (
    <div className={className} style={{ background: "#0D3F62" }}></div>
  ) : loaded ? (
    <div className={className} style={{ backgroundImage: `url(${loaded})` }}>
      {items ? items : ""}
    </div>
  ) : (
    <Center>
      <Loader />
    </Center>
  )
}

export default LazyBackground
